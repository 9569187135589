import {
	Box,
	Divider,
	ImageList,
	Paper,
	styled,
	SvgIcon,
	Typography,
} from "@mui/material";
import { height, maxHeight } from "@mui/system";
import { ClipLoader } from "react-spinners";
import { useAppSelector } from "../../Redux/hooks";
import { ImageDisplay } from "../ImageDisplay/ImageDisplay";
import { PriceTableView } from "../PriceTableView/PriceTableView";

const ImageContainer = styled("div")({
	display: "flex",
});

export const ModelView = () => {
	const model = useAppSelector((state) => state.models.model);
	const isLoadingModel = useAppSelector((state) => state.models.modelLoading);

	if (isLoadingModel) {
		return (
			<Paper
				sx={{
					marginTop: "70px",
					minWidth: "800px",
					minHeight: "650px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ClipLoader size="90px" />
			</Paper>
		);
	}

	return (
		<Box sx={{ width: "calc(100% - 200px)" }}>
			{model && (
				<>
					<Paper sx={{
						margin: "5px", marginTop: "70px", padding: "8px"
					}}>
						<div>
							<Typography sx={{ color: "text.secondary" }} display="inline">
								Platform:{" "}
							</Typography>
							<Typography display="inline">{model.platform}</Typography>
						</div>
						<Typography sx={{ color: "text.secondary" }} display="inline">
							{" "}
							Name:{" "}
						</Typography>
						<Typography display="inline">{model.model}</Typography>
						<Divider />
						<ImageList sx={{ height: 280 }} cols={6} rowHeight={120}>
							{model.pictures.map((pictureInfo) => (
								<ImageDisplay
									key={pictureInfo?.rowId}
									rowId={pictureInfo?.rowId}
									source={pictureInfo?.imageData}
									modelName={pictureInfo?.modelName}
								/>
							))}
						</ImageList>
						<Divider />
						{model.priceTables?.map((priceTable) => {
							return (
								<PriceTableView
									modelName={model.model ?? ""}
									prefix={model.prefix ?? ""}
									platform={model.platform ?? ""}
									key={priceTable.name}
									priceTable={priceTable}
								></PriceTableView>
							);
						})}
					</Paper>
				</>
			)}
		</Box>
	);
};
