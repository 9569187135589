import { TreeDrawer } from "../TreeDrawer/TreeDrawer";
import { AppBar, Box, styled, Typography } from "@mui/material";
import { ModelView } from "../ModelView/ModelView";
import logo from "../../Images/Aradas_Black.png";

const drawerWidth: number = 200;

const AradasLogo = styled("img")({
	maxHeight: "20px",
});

export const Dashboard = () => {
	return (
		<Box sx={{ display: "flex" }}>
			<TreeDrawer drawerWidth={drawerWidth} />
			<AppBar
				position="fixed"
				sx={{
					backgroundColor: "#e6f3f9",
          height: "60px",
          width: `calc(100% - ${drawerWidth}px)`,
					ml: { sm: `${drawerWidth}px` },
				}}
			>
				<Box sx={{ margin: "20px" }}>
					<AradasLogo alt="logo" src={logo}></AradasLogo>
				</Box>
			</AppBar>
			<ModelView />
		</Box>
	);
};
