
import TreeItem from '@mui/lab/TreeItem';
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { PriceGridClient } from "../../../client";
import { ModelTreeItem } from "../ModelTreeItem/ModelTreeItem";

type PrefixProps = {
    platform: string,
    name: string,
    base: boolean,
    modelQuery?: string
}

export const PrefixTreeItem: React.FC<PrefixProps> = (props) => {

    const [models, setModels] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchModels = async () => {
            var client = new PriceGridClient();
            const m = await client.model(props.platform, props.name, props.base, props.modelQuery);
            setModels(m);
            setIsLoading(false);
        }
        fetchModels();
    }, []);

    const filter = (data: string[]): string[] => {
        return data.filter((item) => item !== "");
    }

    return(
        <TreeItem nodeId={props.platform+props.name} label={props.name}>
            {
                models.length === 0 
                ?
                <ClipLoader loading={isLoading} size={20} />
                :
                filter(models).map((model) => {
                    return  <ModelTreeItem key={props.platform+props.name+model} platform={props.platform} prefix={props.name} base={props.base} name={model} />
                })
            }
        </TreeItem>
    )
}