import { ModelPriceData } from "../../client";

const initialState = {
    model: undefined,
    modelLoading: false
};

interface State {
    model?: ModelPriceData,
    modelLoading: boolean
}

export enum ActionType {
    UPDATE_MODEL_VIEW = 'UPDATE_MODEL_VIEW',
    SET_MODEL_LOADING = 'MODEL_LOADING'
}

interface actionModelClicked {
    type: ActionType.UPDATE_MODEL_VIEW,
    payload: ModelPriceData
}

interface actionModelFetched {
    type: ActionType.SET_MODEL_LOADING,
}

type Action = actionModelClicked | actionModelFetched;

export const modelsReducer = (state : State = initialState, action : Action) : State => {
 
    switch (action.type) {
        case ActionType.UPDATE_MODEL_VIEW:
            return {
                modelLoading: false,
                model: action.payload
            }
        case ActionType.SET_MODEL_LOADING:
            return {
                modelLoading: true
            }
        default:
        // If this reducer doesn't recognize the action type, or doesn't
        // care about this specific action, return the existing state unchanged
            return state
    }
}



