import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { modelsReducer } from './modelsReducer';

const reducers = combineReducers({
    models: modelsReducer,
    authInfo: authReducer
});

export default reducers;
//This RootState is required to use useSelector later on 
export type RootState = ReturnType<typeof reducers>;