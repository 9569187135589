import TreeItem from "@mui/lab/TreeItem";
import { useState } from "react";
import { PrefixTreeItem } from "../PrefixTreeItem/PrefixTreeItem";
import ClipLoader from "react-spinners/ClipLoader";
import { PriceGridClient } from "../../../client";

type PlatformProps = {
	name: string;
	base: boolean;
	model?: string;
};

export const PlatformTreeItem: React.FC<PlatformProps> = (props) => {
	const [prefixes, setPrefixes] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = async () => {
		if (prefixes.length > 0 || isLoading) return;
		setIsLoading(true);
		const client = new PriceGridClient();
		const p = await client.prefix(props.name, props.base, props.model);
		setPrefixes(p);
		setIsLoading(false);
	};

	return (
		<TreeItem nodeId={props.name} label={props.name} onClick={handleClick}>
			{prefixes.length === 0 ? (
				<ClipLoader loading={isLoading} size={20} />
			) : (
				prefixes.map((prefix) => {
					return (
						<PrefixTreeItem
							key={props.name + prefix}
							platform={props.name}
							name={prefix}
                            base={props.base}
							modelQuery={props.model}
						></PrefixTreeItem>
					);
				})
			)}
		</TreeItem>
	);
};
