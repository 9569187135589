import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	IconButton,
	Snackbar,
} from "@mui/material";
import { PriceTables, PriceGridClient } from "../../client";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { CopyToClipboard } from "react-copy-to-clipboard";

type PriceTableProps = {
	modelName: string;
	platform: string;
	prefix: string;
	priceTable: PriceTables;
};

export const PriceTableView = ({
	modelName,
	platform,
	prefix,
	priceTable,
}: PriceTableProps) => {
	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const inputRef = React.useRef(null);

	const makePriceTableCopy = (): string => {
		let clipboard = "";
		priceTable.prices?.forEach((row) => {
			clipboard += row.join("\t");
			clipboard += "\r\n";
		});
		return clipboard;
	};

	const handleDownloadIconClick = async (version: string | undefined) => {
		// @ts-ignore
		//inputRef?.current?.click();

		if (!priceTable.name) return;

		const client = new PriceGridClient();
		client.csv(platform, prefix, modelName, priceTable.name).then((data) => {
			var a = document.createElement("a");
			a.href = window.URL.createObjectURL(data.data);
			a.download = modelName + "_PVC_" + priceTable.name + "_EUR";
			a.click();
		});
	};

	const handleCopyIconClick = () => {
		setSnackBarOpen(true);
	};

	const handleClose = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackBarOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<div style={{ marginTop: "5px" }}>
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={2000}
				onClose={handleClose}
				message="Prices copied to clipboard"
				action={action}
			/>
			<Typography sx={{ color: "text.secondary" }} display="inline">
				Prices for:{" "}
			</Typography>
			<Typography display="inline">{priceTable.name}</Typography>
			<Box>
				<IconButton onClick={handleCopyIconClick}>
					<CopyToClipboard
						options={{ format: "text/plain" }}
						text={makePriceTableCopy()}
					>
						<ContentCopyIcon color="secondary"></ContentCopyIcon>
					</CopyToClipboard>
				</IconButton>
				<IconButton
					onClick={() => handleDownloadIconClick(priceTable.name)}
				>
					<DownloadIcon color="secondary"></DownloadIcon>
				</IconButton>
			</Box>
			<TableContainer
				sx={{
					height: 400,
				}}
				component={Paper}
			>
				<Table
					stickyHeader
					sx={{ height: "max-content", minWidth: "850px" }}
					aria-label="price table"
				>
					<TableHead>
						<TableRow>
							<TableCell>
								<span>&nbsp;&nbsp;</span>
							</TableCell>
							{priceTable.widths?.map((width) => {
								return (
									<TableCell
										key={priceTable.name + "-cell-" + width}
										align="right"
									>
										{width / 10}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{priceTable.heights?.map((height, hIndex) => {
							return (
								<TableRow
									key={priceTable.name + "-height-" + height}
									sx={{
										backgroundColor: `${
											hIndex % 2 === 0 ? "rgba(98, 131, 149, 0.1)" : "white"
										}`,
									}}
								>
									<TableCell
										sx={{
											position: "sticky",
											left: 0,
											backgroundColor: `${
												hIndex % 2 === 0 ? "#EFF2F4" : "white"
											}`,
											borderRight: "1px solid rgba(224, 224, 224, 1)",
										}}
									>
										{height / 10}
									</TableCell>
									{priceTable.prices &&
										priceTable.prices[hIndex].map((price, pIndex) => {
											return (
												<TableCell
													key={
														priceTable.name +
														"-" +
														height +
														"-" +
														price +
														"-" +
														hIndex +
														"-" +
														pIndex
													}
													sx={{ color: "text.secondary", minWidth: "50px" }}
													align="right"
												>
													{price}
												</TableCell>
											);
										})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};
