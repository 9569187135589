import {
	Button,
	Divider,
	Drawer,
	FormControlLabel,
	FormGroup,
	IconButton,
	Input,
	InputAdornment,
	Switch,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import { PlatformTreeItem } from "./PlatformTreeItem/PlatformTreeItem";
import { ClipLoader } from "react-spinners";
import { PriceGridClient } from "../../client";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import axios from "axios";
import fileDownload from "js-file-download";

type TreeDrawerProps = {
	drawerWidth: number;
};

export const TreeDrawer = ({ drawerWidth }: TreeDrawerProps) => {
	const [platforms, setPlatforms] = useState<string[]>([]);
	const [expanded, setExpanded] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [modelQuery, setModelQuery] = useState<string>("");
	const [fetchBase, setFetchBase] = useState(false);
	const [isBulkFetching, setBulkFetching] = useState(false);
	const controller = new AbortController();

	const fetchPlatforms = async () => {
		const client = new PriceGridClient();
		const p = await client.platformsAll(fetchBase, modelQuery);
		setPlatforms(p);
		setIsLoading(false);
		setExpanded([]);
	};

	useEffect(() => {
		fetchPlatforms();

		if (isBulkFetching) {
			window.onbeforeunload = function () {
				return true;
			};

			window.onunload = function () {
				controller.abort();
			};
		}

		return () => {
			window.onbeforeunload = null;
		};
	}, [isBulkFetching]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setModelQuery(event.target.value);
	};

	const handleBaseSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFetchBase(event.target.checked);
		setPlatforms([]);
		fetchPlatforms();
	};

	const handleSearchClick = () => {
		setPlatforms([]);
		fetchPlatforms();
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (event.key === "Enter") {
			setPlatforms([]);
			fetchPlatforms();
		}
	};

	const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
		setExpanded(nodeIds);
	};

	const handleMouseDownSearch = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};


	const downloadBulk = () => {
		setBulkFetching(true);
		let customer = sessionStorage.getItem("customer");
		let token = sessionStorage.getItem("token");
		if(!customer || !token){
			console.log("no token / customer");
			setBulkFetching(false);
			return;
		}

		axios
			.get(process.env.REACT_APP_PRICE_GRID_API_URL + "/api/" + customer + "/zip", {
				responseType: "blob",
				signal: controller.signal,
				headers: {Authorization: `Bearer ${token}`}
			})
			.then((res) => {
				setBulkFetching(false);
				fileDownload(res.data, "aradas-pricegrid-bulk.zip");
			});
	};

	return (
		<Drawer
			sx={{
				width: drawerWidth,
				paddingLeft: "10px",
				flexShrink: 0,
				"& .MuiDrawer-paper": {
					width: drawerWidth,
					boxSizing: "border-box",
				},
			}}
			variant="permanent"
			anchor="left"
		>
			<Typography sx={{ margin: "0px 10px", paddingTop: "2px" }}>
				Platforms:
			</Typography>
			<Divider />
			<Input
				sx={{ margin: "0px 15px 10px", paddingTop: "5px" }}
				type="text"
				placeholder="search"
				value={modelQuery}
				onChange={handleChange}
				onKeyDown={(e) => handleKeyDown(e)}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleSearchClick}
							onMouseDown={handleMouseDownSearch}
							edge="end"
						>
							<SearchIcon />
						</IconButton>
					</InputAdornment>
				}
			/>
			<TreeView
				aria-label="rich object"
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpanded={["root"]}
				defaultExpandIcon={<ChevronRightIcon />}
				expanded={expanded}
				onNodeToggle={handleToggle}
			>
				{platforms.length === 0 ? (
					<div style={{ marginLeft: "70px" }}>
						<ClipLoader loading={isLoading} size={40} />
					</div>
				) : (
					platforms.map((platform) => {
						return (
							<PlatformTreeItem
								key={platform}
								name={platform}
								base={fetchBase}
								model={modelQuery}
							></PlatformTreeItem>
						);
					})
				)}
			</TreeView>
			<Divider sx={{ marginTop: "10px" }} />
			<FormGroup>
				<FormControlLabel
					control={
						<Switch
							sx={{ margin: "0px 15px" }}
							checked={fetchBase}
							onChange={handleBaseSwitch}
						/>
					}
					label="Base Model"
				/>
			</FormGroup>
			<Divider />
			<Button
				sx={{ marginTop: "10px" }}
				startIcon={
					isBulkFetching ? <ClipLoader size="20px" /> : <ArrowDownwardIcon />
				}
				variant="text"
				disabled={isBulkFetching}
				onClick={() => downloadBulk()}
			>
				Download Bulk
			</Button>
		</Drawer>
	);
};
