import React, { useState } from "react";
import { Snackbar, Alert, SnackbarCloseReason } from "@mui/material";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../Redux/hooks";
import { ActionType } from "../../Redux/Reducers/authReducer";
import axios from "axios";

type LoginProps = {};

export const Login = () => {
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [snackBarOpen, setOpen] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [errorInfo, setErrorInfo] = useState("");
	const controller = new AbortController();

	const handleSnackClose = (
		event: Event | React.SyntheticEvent<any, Event>,
		reason: SnackbarCloseReason
	) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	const handleLogin = () => {
		setIsFetching(true);
		axios
			.post(process.env.REACT_APP_PRICE_GRID_API_URL + "/api/login", {
				userName: userName,
				password: password,
			})
			.then((res) => {
				setIsFetching(false);

				if (!res.data || res.data === "") {
					setIsFetching(false);
					setErrorInfo("Login failed");
					setOpen(true);
					return;
				}

				sessionStorage.setItem("token", res.data.token);
				sessionStorage.setItem("customer", userName);
				
				dispatch({ type: ActionType.LOGIN, payload: res.data });
				navigate("/");
			})
			.catch((ex) => {
				setIsFetching(false);
				setErrorInfo("Login failed");
				setOpen(true);
			});
	};

	return (
		<div className={styles.container}>
			<Snackbar
				sx={{ marginTop: "80px" }}
				open={snackBarOpen}
				autoHideDuration={3000}
				onClose={(event, reason) => handleSnackClose(event, reason)}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Alert severity="error" sx={{ width: "100%" }}>
					{errorInfo}
				</Alert>
			</Snackbar>
			<form className={styles.loginForm}>
				<h3 className={styles.title}>Log in</h3>
				<input
					className={styles.input}
					type="text"
					autoComplete="username"
					placeholder="Username"
					onChange={(e) => setUserName(e.target.value)}
				/>
				<input
					className={styles.input}
					type="password"
					autoComplete="current-password"
					placeholder="password"
					onChange={(e) => setPassword(e.target.value)}
				/>
				<button
					className={styles.button}
					onClick={handleLogin}
					disabled={isFetching}
				>
					Login
				</button>
			</form>
		</div>
	);
};
