import TreeItem from "@mui/lab/TreeItem";
import { PriceGridClient } from "../../../client";
import { useAppDispatch } from "../../../Redux/hooks";
import { ActionType } from "../../../Redux/Reducers/modelsReducer";

type ModelProps = {
	platform: string;
	prefix: string;
	base: boolean;
	name: string;
};
export const ModelTreeItem = (props: ModelProps) => {
	const dispatch = useAppDispatch();

	const handleModelClick = async () => {
		var client = new PriceGridClient();
		dispatch({ type: ActionType.SET_MODEL_LOADING });
		const model = await client.platforms(
			props.platform,
			props.prefix,
			props.name,
			props.base
		);
		dispatch({ type: ActionType.UPDATE_MODEL_VIEW, payload: model });
	};

	return (
		<TreeItem
			nodeId={props.platform + props.name + props.name}
			label={props.name}
			onClick={handleModelClick}
		/>
	);
};
