import React, { ReactElement, useContext, useState } from "react";
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
import { Login } from "./Components/Login/Login";
import { NotFound } from "./Components/NotFound/NotFound";
import { Dashboard } from "./Components/Dashboard/Dashboard";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useAppSelector } from "./Redux/hooks";

const theme = createTheme({
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        .MuiTreeItem-label.MuiTypography-body1
        {
          font-size: medium;
          font-weight: bold;
        }
        body {
          background-color: #e6f3f9;
        }
      `,
		},
	},
	palette: {
		primary: {
			light: "#35baf6",
			main: "#03a9f4",
			dark: "#0276aa",
			contrastText: "#fff",
		},
		secondary: {
			light: "#cb5e3c",
			main: "#bf360c",
			dark: "#852508",
			contrastText: "#000",
		},
	},
});

interface RequireAuthProps {
	children: JSX.Element;
}

function RequireAuth({ children }: RequireAuthProps): JSX.Element {
	const token = useAppSelector((state) => state.authInfo.token);
	let location = useLocation();

	return token ? (
		children
	) : (
		<Navigate to="/login" replace={true} state={location.pathname} />
	);
}

function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<BrowserRouter>
				<Routes>
					<Route
						path="/"
						element={
							<RequireAuth>
								<Dashboard />
							</RequireAuth>
						}
					></Route>
					<Route path="/login" element={<Login />}></Route>
					<Route path="*" element={<NotFound />}></Route>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
