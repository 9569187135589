import { ImageListItem, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface ImageDisplayProps {
	rowId: string | undefined;
	source: string | undefined;
	modelName: string | undefined;
}

const Dialog = styled("div")({
	top: "0px",
	left: "0px",
	width: "100%",
	height: "100%",
	border: "none",
	background: "rgba(0, 0, 0, 0.5)",
	textAlign: "center",
	justifyContent: "center",
	alignItems: "center",
	display: "flex",
	margin: "auto",
	zIndex: "9999",
});

const ImageLightBox = styled("img")({
	margin: "auto",
	maxWidth: "70%",
	maxHeight: "70%",
});

const Container = styled("div")({
  textAlign: "center",
})

export const ImageDisplay: React.FC<ImageDisplayProps> = ({
	rowId,
	source,
	modelName,
}) => {
	const [displayModal, setDisplayModal] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const onClickOutside = (e: any) => {
		if (e.target.localName === "img") {
			return;
		}

		setDisplayModal(false);
    document.body.style.overflow = '';
	};

	const handleModalClick = () => {
    document.body.style.overflow = '';
		setDisplayModal(false);
	};

	const handleImageClick = () => {
    //window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
		setDisplayModal(true);
	};

	useEffect(() => {
		window.addEventListener("click", onClickOutside);
		return () => window.removeEventListener("click", onClickOutside);
	}, []);

	return (
		<Container>
			<ImageListItem key={rowId}>
				<img
					src={source}
					style={{ padding: "5px", maxHeight: "250px", objectFit: "contain"}}
					alt={modelName}
					onClick={() => handleImageClick()}
					loading="lazy"
					onLoad={() => setLoaded(true)}
				/>
        <Typography>{modelName}</Typography>
			</ImageListItem>
			{displayModal && (
				<Dialog style={{ position: "absolute" }}>
					<ImageLightBox
						src={source}
						alt={modelName}
						onClick={() => handleModalClick()}
						loading="lazy"
					/>
				</Dialog>
			)}
		</Container>
	);
};
