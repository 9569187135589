const initialState = {
  token: (typeof sessionStorage !== "undefined" && sessionStorage.getItem("token")) ? sessionStorage.getItem("token") : undefined
};

interface State {
  token?: string | null,
}

export enum ActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

interface actionLogin {
  type: ActionType.LOGIN,
  payload: string
}

interface actionLogout {
  type: ActionType.LOGOUT,
}

type Action = actionLogin | actionLogout;

export const authReducer = (state : State = initialState, action : Action) : State => {

  switch (action.type) {
      case ActionType.LOGIN:
          return {
              token: action.payload,
          }
      case ActionType.LOGOUT:
          return {
              token: undefined
          }
      default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
          return state
  }
}